// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/svg/pArrow.svg?inline");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".pagination li.first,\n.pagination li.last {\n  display: none;\n}\n.pagination {\n  margin-top: 30px;\n  color: #6B7B98;\n  display: flex;\n  gap: 0.5em;\n}\n.pagination a {\n  display: flex;\n  font-family: \"Tektur\";\n  font-weight: 600;\n  font-size: 18px;\n  line-height: 22px;\n  padding: 6px 12px;\n}\n.page-number.active a {\n  font-size: 18px;\n  background: -webkit-linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n.pagination li.previous a {\n  content: \"\";\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center center;\n  height: 100%;\n  width: 32px;\n  background-repeat: no-repeat;\n}\n.pagination li.next a {\n  content: \"\";\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center center;\n  height: 100%;\n  width: 32px;\n  background-repeat: no-repeat;\n  transform: rotate(180deg);\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
