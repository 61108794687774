<template>
    <vue-countdown class="stake-timer" :time="time" :transform="transformSlotProps" v-slot="{ days, hours, minutes }">
        <div class="stake-timer__wrap">
            <div class="stake-timer__item">{{ days }}d</div>
            <div class="stake-timer__item">{{ hours }}h</div>
            <div class="stake-timer__item">{{ minutes }}min</div>
        </div>
    </vue-countdown>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
    components: {
        VueCountdown
    },
    props: {
        startDate: {
            type: String,
        },
        endDate: {
            type: String,
        }

    },
    methods: {
        transformSlotProps(props) {
            const formattedProps = {};

            Object.entries(props).forEach(([key, value]) => {
                formattedProps[key] = value < 10 ? `0${value}` : String(value);
            });

            return formattedProps;
        },
    },
    computed: {
        time() {
            const gap = new Date(this.endDate) - new Date();
            return (gap > 0) ? gap : 0;
        },
        // finish() {
        //     return new Date(this.endDate) - new Date(this.startDate);
        // },
    }
}
</script>

<style lang="scss" scoped>
.stake-timer {
    &__wrap {
        display: flex;
        justify-content: center;
        gap: 8px;
        text-transform: lowercase;
    }
}

@media screen and (max-width: ($bp_mb - 1px)) {
    .stake-timer {
        &__wrap {
            gap: 4px;
            flex-wrap: wrap;
        }
    }
}
</style>
