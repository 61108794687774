var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vue-countdown", {
    staticClass: "stake-timer",
    attrs: { time: _vm.time, transform: _vm.transformSlotProps },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ days, hours, minutes }) {
          return [
            _c("div", { staticClass: "stake-timer__wrap" }, [
              _c("div", { staticClass: "stake-timer__item" }, [
                _vm._v(_vm._s(days) + "d"),
              ]),
              _c("div", { staticClass: "stake-timer__item" }, [
                _vm._v(_vm._s(hours) + "h"),
              ]),
              _c("div", { staticClass: "stake-timer__item" }, [
                _vm._v(_vm._s(minutes) + "min"),
              ]),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }