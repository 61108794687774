var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-stake-table card" }, [
    _c("div", { staticClass: "my-stake-table__thead card-header" }, [
      _c("div", { staticClass: "my-stake-table__thead-tr" }, [
        _c(
          "div",
          {
            staticClass:
              "my-stake-table__thead-th my-stake-table__thead-th--id",
          },
          [_vm._v(" Stake ")]
        ),
        _c(
          "div",
          {
            staticClass:
              "my-stake-table__thead-th my-stake-table__thead-th--type",
          },
          [_vm._v(" Type ")]
        ),
        _vm.showTime
          ? _c(
              "div",
              {
                staticClass:
                  "my-stake-table__thead-th my-stake-table__thead-th--time",
              },
              [_vm._v(" Time ")]
            )
          : _vm._e(),
        _c("div", { staticClass: "my-stake-table__thead-th" }, [
          _vm._v(" Price "),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "my-stake-table__bg" }, [
      _c("table", { staticClass: "my-stake-table__table" }, [
        _c(
          "tbody",
          { staticClass: "my-stake-table__body card-body" },
          _vm._l(_vm.rows, function (row, index) {
            return _c("tr", { key: index, staticClass: "my-stake-table__tr" }, [
              _c(
                "td",
                { staticClass: "my-stake-table__td my-stake-table__td--id" },
                [_c("div", [_vm._v("#" + _vm._s(row.stake_id))])]
              ),
              _c(
                "td",
                { staticClass: "my-stake-table__td my-stake-table__td--type" },
                [_vm._v(_vm._s(row.stake_type))]
              ),
              _vm.showTime
                ? _c(
                    "td",
                    {
                      staticClass:
                        "my-stake-table__td my-stake-table__td--time",
                    },
                    [
                      _c("MyStakeTimer", {
                        attrs: {
                          startDate: row.created_at,
                          endDate: row.expired_at,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("td", { staticClass: "my-stake-table__td" }, [
                _vm._v(_vm._s(_vm.formattedPrize(row.token_amount)) + " MF"),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "card-footer" },
      [
        _c("Pagination", {
          attrs: { pageCount: _vm.pageCount, currentPage: _vm.page },
          on: { clickHandler: _vm.selectPage },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }