<template>
    <div class="my-stake-table card">
        <div class="my-stake-table__thead card-header">
            <div class="my-stake-table__thead-tr">
                <div class="my-stake-table__thead-th my-stake-table__thead-th--id">
                    Stake
                </div>
                <div class="my-stake-table__thead-th my-stake-table__thead-th--type">
                    Type
                </div>
                <div
                    v-if="showTime"
                    class="my-stake-table__thead-th my-stake-table__thead-th--time"
                >
                    Time
                </div>
                <div class="my-stake-table__thead-th">
                    Price
                </div>
            </div>
        </div>
        <div class="my-stake-table__bg">
            <table class="my-stake-table__table">
                <tbody class="my-stake-table__body card-body">
                    <tr
                        v-for="(row, index) in rows"
                        :key="index"
                        class="my-stake-table__tr"
                    >
                        <td class="my-stake-table__td my-stake-table__td--id">
                            <div>#{{ row.stake_id }}</div>
                        </td>
                        <td class="my-stake-table__td my-stake-table__td--type">{{ row.stake_type }}</td>
                        <td
                            v-if="showTime"
                            class="my-stake-table__td my-stake-table__td--time"
                        >
                            <MyStakeTimer :startDate="row.created_at" :endDate="row.expired_at"/>
                        </td>
                        <td class="my-stake-table__td">{{ formattedPrize(row.token_amount) }} MF</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card-footer">
            <Pagination
                :pageCount="pageCount"
                :currentPage="page"
                @clickHandler="selectPage"
            />
        </div>
    </div>
</template>

<script>
import MyStakeTimer from '../staking/MyStakeTimer.vue';
import Pagination from '../common/pagination.vue';

export default {
    name: 'MyStakeTable',
    components: {
        Pagination,
        MyStakeTimer,
    },
    props: {
        rows: Array,
        pageCount: Number,
        page: Number,
        showTime: Boolean
    },
    methods: {
        formattedPrize(value) {
            return this.toLocaleFormat((+value).toFixed(2));
        },
        selectPage(value) {
            this.$emit('update:page', value);
        }
    }
}
</script>

<style lang="scss" scoped>
    .my-stake-table {
        max-width: 800px;
        margin: 0 auto;

        &__table {
            width: 100%;
        }

        &__body {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        &__thead {
            padding-left: 40px;
            padding-right: 40px;
            margin-bottom: 20px;
        }

        &__thead-tr {
            display: flex;
            gap: 20px;
        }

        &__thead-th {
            display: flex;
            font-family: 'Raleway';
            font-weight: 800;
            font-size: 24px;
            line-height: 117%;
            text-transform: uppercase;
            color: #ffffff;

            &--id {
                width: 100px;
            }

            &--checkbox {
                display: flex;
                gap: 10px;
                align-items: center;
            }

            &--time {
                width: 135px;
            }

            &--type {
                display: flex;
                width: 275px;
            }
        }

        &__bg {
            padding: 40px;
            backdrop-filter: blur(7px);
            background-color: rgba(255, 255, 255, 0.1);
            box-shadow: 3px 4px 4px 0px rgba(255, 255, 255, 0.20) inset,
                0px 4px 4px rgba(0, 0, 0, 0.25),
                0px 1px 4px rgba(0, 55, 96, 0.15);
            border-radius: 5px;
        }

        &__tr {
            display: flex;
            gap: 20px;
        }

        &__td {
            font-family: 'Tektur';
            font-weight: 500;
            font-size: 18px;
            line-height: 130%;
            text-transform: uppercase;
            color: #ffffff;

            &--id {
                width: 100px;
            }

            &--time {
                width: 135px;
            }

            &--type {
                display: flex;
                width: 275px;
            }

            &--mobile {
                display: none;
            }
        }

        &__pagination {
            justify-content: flex-end;
            cursor: pointer;
        }

        &__btns-list {
            display: flex;
            gap: 25px;
            justify-content: center;
        }

        &__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-width: 200px;
            min-height: 48px;
            padding: 10px 20px;

            font-family: "Tektur";
            font-size: 18px;
            font-weight: 500;
            line-height: 22.5px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
        }

        &__footer-wrap {
            margin-top: 30px;
        }
    }

    @media screen and (max-width: ($bp_desktop - 1px)) {
        .my-stake-table {
            &__thead {
                padding-left: 10px;
                padding-right: 10px;
            }

            &__thead-tr {
                gap: 10px;
            }

            &__thead-th {
                flex: 1 1 0;
                justify-content: center;
                min-width: unset;
                font-size: 18px;

                &--id {
                    width: unset;
                }

                &--time {
                    width: unset;
                }
            }

            &__tr {
                gap: 10px;
            }

            &__td {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1 1 0;
                justify-content: center;
                font-size: 16px;
                text-align: center;

                &--id {
                    width: unset;
                }
            }

            &__bg {
                padding: 10px;
            }

            &__btns-list {
                justify-content: center;
                gap: 15px;
            }

            &__btn {
                min-width: 130px;
                min-height: 40px;
                font-size: 16px;
            }
        }
    }

    @media screen and (max-width: ($bp_mb - 1px)) {
        .my-stake-table {
            &__body {
                gap: 0;
            }

            &__thead {
                padding: 0;
            }

            &__thead-tr {
                gap: 7px;
            }

            &__thead-th {
                flex: 1 1 0;
                justify-content: center;
                min-width: unset;
                font-size: 16px;

                &--type {
                    display: none;
                }

                &--id {
                    width: unset;
                }

                &--time {
                    width: unset;
                }
            }

            &__tr {
                gap: 7px;
            }

            &__td {
                display: flex;
                flex-direction: column;
                align-items: center;
                min-width: 90px;
                flex: 1 1 0;
                justify-content: center;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                padding-top: 10px;
                padding-bottom: 10px;

                &--type {
                    display: none;
                }

                &--mobile {
                    display: flex;
                }

                &--id {
                    width: unset;
                }

                &--time {
                    width: unset;
                    border-left: 1px solid #6B7B98;
                    border-right: 1px solid #6B7B98;
                }
            }

            &__bg {
                padding: 0;
                background-color: unset;
                box-shadow: none;
                backdrop-filter: none;
                border-radius: 0;
                border-top: 1px solid #6B7B98;
            }

            &__pagination {
                justify-content: center;
            }

            &__btn {
                min-width: 96px;
                min-height: 36px;
                padding: 4px 4px;

                font-size: 14px;
                line-height: 17.5px;
            }
        }
    }
    
    .card-footer {
        width: fit-content;
        margin-right: 0;
        margin-left: auto;
        color: white;
    }

    
    // .pagination {
    //     background-color: red;
    // }
</style>

<style lang="scss">
    .pagination li.first,
    .pagination li.last {
        display: none;
    }

    .pagination {
        margin-top: 30px;
        color: #6B7B98;
        display: flex;
        gap: 0.5em;
    }

    .pagination a {
        display: flex;
        font-family: 'Tektur';
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        padding: 6px 12px;
    }

    .page-number.active a {
        font-size: 18px;
        background: -webkit-linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .pagination li.previous a {
        content: '';
        background: url('../../assets/svg/pArrow.svg?inline');
        background-position: center center;
        height: 100%;
        width: 32px;
        background-repeat: no-repeat;
    }

    .pagination li.next a {
        content: '';
        background: url('../../assets/svg/pArrow.svg?inline');
        background-position: center center;
        height: 100%;
        width: 32px;
        background-repeat: no-repeat;
        transform: rotate(180deg);
    }
</style>